import { Button } from 'antd';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as LineAppIcon } from '../../icons/line-app-icon.svg';
import Card, { CardBody, CardHeader } from '../ui/Card';

const CashPaymentInstruction = ({
  header = true,
  secondary = false,
}: {
  header?: boolean;
  secondary?: boolean;
}) => {
  const tsaBankAccountNumber = '405-1-01374-9';
  const excelBankAccountNumber = '021-8-48599-5';

  return (
    <Card border={!secondary}>
      {header && <CardHeader>รายละเอียดการชำระเงิน</CardHeader>}
      <CardBody className={twMerge(secondary && 'bg-transparent p-0')}>
        <div className="text-sm">
          <div className="flex flex-col gap-6">
            <Section title="ช่องทางการชำระเงิน">
              <DetailItem title="ธนาคาร">กสิกรไทย / นวนคร</DetailItem>
              <DetailItem title="ชื่อบัญชี">
                TSA Group (Thailand) Co.,Ltd.
              </DetailItem>
              <DetailItem title="เลขบัญชีธนาคาร">
                {tsaBankAccountNumber}{' '}
                <span
                  className="cursor-pointer text-primary-6 underline"
                  onClick={() => {
                    navigator.clipboard.writeText(tsaBankAccountNumber);
                  }}
                >
                  คัดลอก
                </span>
              </DetailItem>
            </Section>
            {!secondary && <hr className="border-light-divider" />}
            <Section title="ช่องทางการชำระเงิน">
              <DetailItem title="ธนาคาร">กสิกรไทย / นวนคร</DetailItem>
              <DetailItem title="ชื่อบัญชี">
                ห้างหุ้นส่วนจำกัด เอ็กเซลอะไหล่ยนต์ (2002)
              </DetailItem>
              <DetailItem title="เลขบัญชีธนาคาร">
                {excelBankAccountNumber}{' '}
                <span
                  className="cursor-pointer text-primary-6 underline"
                  onClick={() => {
                    navigator.clipboard.writeText(excelBankAccountNumber);
                  }}
                >
                  คัดลอก
                </span>
              </DetailItem>
            </Section>
            <Section title="ขั้นตอนการชำระเงิน">
              <a
                href="https://line.me/ti/p/b-TM70Hfg_"
                target="_blank"
                className="col-span-2 rounded-md border border-gray-300 py-2 px-3 text-light-title hover:bg-gray-50"
              >
                <div className="flex w-full items-center gap-3">
                  <LineAppIcon className="h-6 w-6" />
                  <span className="text-light-title">
                    ส่งหลักฐานการชําระเงินผ่านทางไลน์
                  </span>
                </div>
              </a>
            </Section>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const Section = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="grid grid-cols-[minmax(80px,180px),minmax(100px,1fr)] gap-1">
      <div className="col-span-2 mb-1 font-semibold text-light-title">
        {title}
      </div>
      {children}
    </div>
  );
};

const DetailItem = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <>
      <div className="font-medium text-light-secondary">{title}</div>
      <div className="overflow-hidden break-words text-light-title">
        {children}
      </div>
    </>
  );
};

export default CashPaymentInstruction;
